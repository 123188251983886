.func-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 44px;
    column-gap: 20px;

    li {
        display: flex;
        flex: 1 1 30%;
        align-items: center;

        transition: 1s;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        strong {
            display: inline-block;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 8px;
        }

        p {
            font-size: 12px;
        }
    }
}

.circle-sm {
    --size: 100px;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 10px 24px;
    flex: none;
    margin-right: 14px;
    height: var(--size);
    width: var(--size);
    @include _840 {
        --size: 80px;
        padding: 8px 20px;
    }

    svg {
        fill: #fff;
        stroke: #fff;
    }
}