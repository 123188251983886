.plan {
    margin-bottom: 310px;
    @include _840 {
        margin-bottom: 60px;
    }
}

.plan-row {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    @include _840 {
        flex-wrap: wrap;
    }
}

.plan-wrap {
    position: relative;

    &__svg {
        @include _840 {
            display: none;
        }
    }
}

.plan-item {
    font-size: 26px;
    font-weight: 700;
    position: relative;
    padding-left: 50px;
    position: absolute;
    width: 40%;
    @include _840 {
        position: relative;
        top: 0 !important;
        left: 0 !important;

        padding-left: 26px;
        font-size: 16px;
        margin-bottom: 30px;
        width: 100%;
    }

    &.--one {
        top: 4%;
        left: -0.5%;
    }

    &.--two {
        top: 47%;
        left: 19%;

        strong::before {
            top: -95px;
            left: -20px;
        }
    }

    &.--three {
        top: -3%;
        left: 42.5%;
    }

    &.--four {
        top: 16%;
        left: 58.5%;
    }

    &.--five {
        top: 48%;
        left: 59%;
    }

    &.--main {
        top: 74%;
        left: 39.5%;

        font-size: 32px;
        margin-top: 40px;
        margin-left: 100px;
        width: 50%;
        @include _840 {
            font-size: 20px;
            margin: 0;
            width: 100%;
        }

        strong::before {
            top: -15px;
            left: -50px;
        }

        .icon {
            --size: 140px;
            margin-left: 70px;
            @include _840 {
                --size: 100px;
                margin-left: 0;
            }
        }

        strong {
            color: var(--primary);
        }

        p {
            font-weight: 300;
        }

        small {
            margin-top: 20px;
            display: block;
            font-size: 22px;
            font-weight: 400;
            @include _840 {
                font-size: 16px;
            }
        }
    }

    .icon {
        --size: 64px;
        height: var(--size);
        width: var(--size);
        margin-bottom: 14px;
        @include _840 {
            --size: 50px;
            margin-bottom: 10px;
        }
    }

    strong {
        position: relative;
        font-weight: 700;

        &::before {
            --size: 18px;
            content: '';
            position: absolute;
            left: -50px;
            top: 50%;
            height: var(--size);
            width: var(--size);
            border-radius: 50%;
            background-color: var(--primary);
            transform: translateY(-50%);
            @include _840 {
                --size: 16px;
                left: -28px !important;
                top: 50% !important;
            }
        }
    }
}