.uniq-list {
    display: flex;
    justify-content: center;
    gap: 40px;
    row-gap: 60px;
    flex-wrap: wrap;
    list-style: none;
    @include _840 {
        padding: 0;
        row-gap: 40px;
    }

    &__item {
        font-size: 14px;
        width: 35%;
        @include _840 {
            width: 100%;
        }

        .circle {
            --size: 120px;
            background-color: var(--primary);
            border-radius: 50%;
            padding: 34px;
            height: var(--size);
            width: var(--size);
            margin-bottom: 38px;
            @include _840 {
                --size: 90px;
                padding: 24px;
                margin-bottom: 20px;
            }
        }

        strong {
            display: block;
            font-size: 18px;
            margin-bottom: 20px;
            @include _840 {
                margin-bottom: 14px;
            }
        }

        p {
            margin-bottom: 20px;
        }

        ul {
            padding-left: 24px;
            list-style: disc;
        }
    }
}