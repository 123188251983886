.practice {
    background-image: url(../img/practice.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 105px 0 220px;
    overflow: hidden;
    @include _840 {
        padding: 100px 0;
    }

    .title {
        margin-bottom: 130px;
        @include _840 {
            margin-bottom: 40px;
        }

        .line::before {
            background-color: #fff;
        }
    }

    &__inner {
        position: relative;
    }
}

.practice-block {
    position: relative;
    margin-bottom: 40px;

    &.--main {
        max-width: 440px;
        @include _840 {
            max-width: 100%;
        }

        .practice-block-circle {
            --size: 300px;
            top: -40%;
            @include _840 {
                --size: 200px;
                top: -10%;
            }
        }
    }

    &.--absolute {
        position: absolute;
        max-width: 385px;
        font-size: 14px;
        @include _840 {
            position: relative;
            max-width: 100%;
        }

        h4 {
            font-size: 24px;
            @include _840 {
                font-size: 20px;
            }
        }

        &-easy {
            top: 40%;
            right: 25%;
            @include _840 {
                top: 0;
                left: 0;
            }

            .practice-block-circle {
                --size: 110px;
                top: -40%;
            }
        }

        &-possible {
            top: -20%;
            right: 0%;
            @include _840 {
                top: 0;
                left: 0;
            }

            .practice-block-circle {
                --size: 280px;
                top: -80%;
                left: -15%;
                @include _840 {
                    --size: 220px;
                    top: -70%;
                }
            }
        }

        &-cost {
            top: 70%;
            right: -5%;
            @include _840 {
                top: 0;
                left: 0;
            }

            .practice-block-circle {
                --size: 150px;
                top: -40%;
                left: 10%;
            }
        }
    }

    h4 {
        font-size: 28px;
        margin-bottom: 20px;
        @include _840 {
            font-size: 22px;
        }
    }

    p {
        color: rgba(255, 255, 255, 0.80);
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.practice-block-circle {
    --size: 200px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.30);
    pointer-events: none;
    position: absolute;
    left: -10%;
    top: -70px;
    height: var(--size);
    width: var(--size);
}