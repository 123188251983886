.header {
    background-color: #fff;
    border-bottom: 1px solid #D9D9D9;
    padding-left: calc((100vw - 1280px) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include _840 {
        padding: 15px 20px;
    }
    @include _841-1640 {
        padding: 0 0 0 30px;
    }

    &__inner {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding-right: 10px;
        width: 100%;
        @include _840 {
            padding-right: 0;
            justify-content: space-between;
        }
        @include _841-1640 {
            justify-content: space-between;
        }
    }

    .col-wide {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 120px;
        width: 40vw;
        @include _840 {
            margin-right: 20px;
            width: auto;
        }
        @include _841-1640 {
            width: auto;
        }
    }

    .phone {
        @include _840 {
            display: none;
        }
    }
}

.btn-menu {
    display: none;
    @include _840 {
        @include no-btn();
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-right: -10px;

        svg {
            width: 20px;
        }
    }
}

.header-menu {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0;
    // margin-right: 15%;
    @include _840 {
        display: none;
    }
    @include _841-1640 {
        white-space: nowrap;
    }

    li.active a {
        font-weight: 600;

        &::after {
            border-color: var(--primary);
        }
    }
}

.header-action {
    @include no-btn();
    background-color: var(--primary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 50px 20px;
    max-width: 450px;
    width: 100%;
    @include _840 {
        display: none;
    }
    @include _841-1640 {
        max-width: 200px;
    }
}

.menu-wrap {
    background-color: #fff;
    padding: 30px 20px;
    position: fixed;
    top: 81px;
    left: 0;
    height: calc(100vh - 81px);
    width: 100vw;
    z-index: 5;
    transition: .2s;

    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;

    &.open {
        opacity: 1;
        transform: none;
        pointer-events: all;
    }

    &::after {
        --size: 150px;
        content: '';
        position: absolute;
        bottom: -15%;
        right: -18%;
        height: 0px;
        width: 0px;
        border-top: solid var(--size) transparent;
        border-left: solid var(--size) var(--primary);
        border-bottom: solid var(--size) transparent;
        display: block;
        transform: rotate(35deg);
        transform-origin: left;
    }

    menu {
        padding: 0;
        list-style: none;

        li {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    .phone {
        display: inline-block;
        margin-top: 40px;
        margin-bottom: 10px;
        font-size: 22px;
    }

    .socials {
        margin-left: -10px;

        a {
            display: inline-block;
            line-height: 1;
            padding: 10px;
        }
    }
}

.btn-up {
    @include no-btn();
    color: var(--primary);
    border: 1px solid var(--primary);
    --size: 50px;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    background-color: #fff;
    position: fixed;
    right: 5%;
    bottom: 5%;
    padding: 12px;
    transition: .2s;

    transform: translateX(10px);
    opacity: 0;
    pointer-events: none;

    &.show {
        transform: none;
        opacity: 0.9;
        pointer-events: all;
    }

    svg {
        display: inline-block;
        fill: currentColor;
        height: 100%;
        width: 100%;
    }
}