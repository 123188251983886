.effect-step {
    counter-reset: effects;
    display: flex;
    align-items: flex-start;
    gap: 28px;
    padding: 0;
    @include _840 {
        flex-wrap: wrap;
    }

    &__item {
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: none;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 38px 62px;
        position: relative;
        transition: .4s;
        width: 33%;
        @include _840 {
            padding: 30px 40px;
            width: 100%;
        }

        &:hover {
            box-shadow: 0px 20px 30px -20px #333;
            transform: translateY(-10px);
        }

        &::after {
            counter-increment: effects;
            content: counter(effects);
            position: absolute;
            left: 20px;
            bottom: 0;
            line-height: 16px;
            font-size: 64px;
            font-weight: 700;
            @include _840 {
                line-height: 28px;
                left: -4px;
            }
        }

        &:first-child {
            background-image: url(../img/2-1.jpg);
        }

        &:nth-child(2) {
            background-image: url(../img/2-2.jpg);
        }

        &:last-child {
            background-image: url(../img/2-3.jpg);
            min-height: 340px;
        }

        strong {
            display: block;
            margin-bottom: 8px;
        }

        p {
            font-size: 14px;

            & + p {
                margin-top: 16px;
            }
        }
    }
}