.client-list {
    display: flex;
    justify-content: center;
    gap: 14px;
    flex-wrap: wrap;
}

.client-item {
    position: relative;
    width: 32%;
    @include _840 {
        width: 100%;
    }

    .text {
        background: rgba(77, 90, 255, 0.95);
        color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        padding: 10px 26px;
        height: 94px;
        width: 100%;
        text-align: center;
    }
}