.consult {
    background-image: url(../img/consult-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 110px 0;
    margin-bottom: 0;
    @include _840 {
        padding: 100px 0;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include _840 {
            flex-wrap: wrap;
        }
    }

    picture {
        @include _840 {
            display: none;
        }
    }

    h3 {
        margin-bottom: 15px;
    }
}

.footer {
    background-color: var(--primary);
    color: #fff;
    // padding: 96px 0 114px;
    padding-bottom: 114px;
    @include _840 {
        padding: 0 0 60px;
    }

    &__inner {
        padding-top: 96px;
        position: relative;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        @include _840 {
            font-size: 20px;
        }

        span {
            font-weight: 300;
        }
    }

    menu {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 24px;
        @include _840 {
            gap: 16px;
            margin-top: 40px;
            flex-direction: column;
        }
    }

    .copyright {
        color: #a6acff;
        font-size: 12px;
        font-weight: 400;
        @include _840 {
            margin-bottom: 10px;
        }
    }

    .socials {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        @include _840 {
            right: -20px;
        }

        a {
            background-color: #fff;
            display: inline-block;
            height: 64px;
            width: 54px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: .2s;

            &:hover {
                background-color: #e9ebff;
            }
        }
    }
}

.footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include _840 {
        flex-wrap: wrap;
    }

    &:nth-of-type(2) {
        margin-bottom: 46px;
        @include _840 {
            margin-bottom: 26px;
        }
    }

    &:last-of-type {
        margin-top: 32px;
        @include _840 {
            margin-top: 20px;
        }
    }
}

.modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.modal__container {
    text-align: center;

    .sended {
        border-radius: 50%;
        border: 3px solid #25b309;
        padding: 7px;
        margin: 0 auto 15px;
        height: 50px;
        width: 50px;

        svg {
            fill: #25b309;
        }
    }
}