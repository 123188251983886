.table {
    border: 1px solid #e0e0e0;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0 auto;
    width: 80%;

    th, td {
        border: 1px solid #e0e0e0;
        padding: 20px 26px;
        font-size: 14px;
        text-align: center;
        width: calc(70% / 4);

        &:first-child {
            width: 30%;
            text-align: left;
        }
    }

    th {
        font-size: 16px;
    }
}

.table-wrap {
    overflow-x: auto;
    width: 100%;
}

.check {
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    height: 26px;
    width: 26px;

    &.--success {
        background-color: #25b309;
    }

    &.--error {
        background-color: #f24612;
    }

    svg {
        display: inline-block;
        fill: currentColor;
    }
}