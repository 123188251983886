.hero {
    padding-top: 120px;
    padding-left: calc((100vw - 1280px) / 2);
    overflow: hidden;
    @include _840 {
        padding: 40px 20px 0;
    }
    @include _841-1640 {
        padding: 80px 30px 0;
    }
}

.hero-top {
    display: flex;
    @include _840 {
        flex-wrap: wrap;
    }

    .col-wide {
        margin-right: 80px;
        width: 42vw;
        @include _840 {
            width: 100%;
            margin-right: 0;
        }
    }

    .hero-head {

        h1 {
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity .8s, transform .8s;

            &.show {
                opacity: 1;
                transform: none;
            }
        }

        p {
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity .8s .5s, transform .8s .5s;

            &.show {
                opacity: 1;
                transform: none;
            }
        }
    }
}

.hero-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include _840 {
        flex-wrap: wrap;
        margin-top: 34px;
    }

    .desc {
        position: relative;
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 1.4;
        padding-top: 20px;
        @include _840 {
            order: 2;
            margin-top: 50px;
            margin-bottom: 0;
        }

        p {
            margin: 20px 0;
            @include _840 {
                br {
                    display: none;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .pulse {
        background-color: var(--green);
        border-radius: 50%;
        margin-bottom: 20px;
        position: relative;
        height: 14px;
        width: 14px;

        &::after,
        &::before {
        content: '';
            position: absolute;
            border: 1px solid var(--green);
            left: -10px;
            opacity: 0;
            right: -10px;
            top: -10px;
            bottom: -10px;
            border-radius: 50%;
            animation: pulse 2.5s linear infinite;
        }

        &::after {
            animation-delay: 1.25s;
        }
    }

    picture {
        width: 70%;
        @include _840 {
            order: 1;
            width: 100%;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

.hero-head {

    h1 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 28px;
        @include _840 {
            br {
                display: none;
            }
        }
    }
}

.desc-1c {
    max-width: 465px;
    @include _840 {
        margin: 34px 0 0;
        max-width: 100%;
    }

    span {
        background-color: #4d5aff40;
    }
}

.hero-goz {
    background-image: url(../img/progoz/hero-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    padding: 80px 0;
    position: relative;

    h1 {
        font-size: 40px;
        margin-bottom: 30px;
        @include _840 {
            font-size: 26px;
        }
    }

    p {
        margin-bottom: 50px;
    }
}