.price-card {
    border: 1px solid #999;
    border-radius: 15px;
    padding: 60px 40px 45px;
    position: relative;
    @include _840 {
        padding: 50px 26px 30px;
    }

    &:not(:last-child) {
        margin-bottom: 100px;
    }

    &--light {
        border-color: #4D5AFF;
    }

    .circle-sm {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
    }

    h3 {
        font-size: 38px;
        line-height: 1.1;
        margin: 14px 0 30px;
        @include _840 {
            font-size: 28px;
        }

        .light {
            color: #4D5AFF;
        }
    }

    h4 {
        font-size: 34px;
        margin-bottom: 36px;
        @include _840 {
            font-size: 24px;
        }

        .light {
            color: #4D5AFF;
        }
    }

    ul {
        padding: 0;
        list-style: none;
        margin-bottom: 30px;

        li {
            margin-bottom: 14px;
            padding-left: 20px;
            position: relative;

            &::before {
                background-color: var(--green);
                content: '';
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 0;
                height: 8px;
                width: 8px;
            }
        }

        &.check-list {
            padding-left: 5px;

            li {
                padding-left: 30px;
                margin-bottom: 20px;

                &::before {
                    background-color: transparent;
                    background-image: url(../img/check.png);
                    content: '';
                    position: absolute;
                    top: -6px;
                    left: -5px;
                    height: 25px;
                    width: 25px;
                }
            }
        }
    }
}

.price-strong {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 26px;
    @include _840 {
        font-size: 18px;
        font-weight: 400;
    }
}

.price-comp {
    color: #333;
    font-size: 32px;
    max-width: 80%;
    @include _840 {
        font-size: 24px;
        max-width: 100%;
    }
}

.price-inner {
    display: flex;
    gap: 20px;
    @include _840 {
        flex-direction: column;
    }

    .col {
        flex: 1 1 50%;
        @include _840 {
            flex: 1 1 100%;
        }
    }
}

.price-action {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.v-align {
    display: flex;
    align-items: center;
}