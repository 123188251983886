*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --primary: #4D5AFF;
    --gray: #808080;
    --green: #47FE7A;
}

html {
    font-size: 16px;
    @include _2000 {
        font-size: 18px;
    }
}

html,
body {
    color: #000;
    font-family: "Onest", sans-serif;
    min-height: 100%;
    position: relative;
}

body {
    background-color: #fff;
    font-size: 1rem;
    line-height: 1.3;
    @include _840 {
        br {
            display: none;
        }
    }

    &.no-scroll {
        overflow: hidden;
    }
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1,
h2 {
    font-size: 48px;
    font-weight: 700;
    @include _840 {
        font-size: 28px;

        br {
            display: none;
        }
    }

    span {
        font-weight: 400;
    }
}

img {
    display: block;
    max-width: 100%;
}

section {
    margin-bottom: 140px;
    @include _840 {
        margin-bottom: 60px;
    }
}

input {
    border: none;
    border-radius: 50px;
    font-size: 16px;
    font-family: inherit;
    padding: 20px 70px 20px 30px;
    outline: none;
    @include _840 {
        width: 100%;
    }
}

.ajax_form span.error {
    color: #f24612;
    display: block;
    margin-top: 7px;
    font-size: 12px;
    line-height: 1.2;
    padding: 0 30px;
}

a {
    color: inherit;
    position: relative;
    text-decoration: none;

    &.--full {
        &:hover {

            &::after {
                width: 50%;
            }
        }
    }

    &:hover,
    li.active & {

        &:not(.no-link)::after {
            width: 26px;
        }
    }

    &:not(.no-link):after {
        content: '';
        border-bottom: 2px solid;
        position: absolute;
        left: 50%;
        bottom: -40%;
        transform: translateX(-50%);
        width: 0;
        transition: .1s;
    }

    &.link-inside {
        color: #00a8eb;
        font-size: 15px;
        position: relative;
        border-bottom: 1px dashed;
    }
}

.phone {
    font-size: 22px;
    @include _840 {
        font-size: 16px;
    }
}

.form-wrap {
    display: flex;
    align-items: center;
    gap: 80px;
    margin-top: 32px;
    @include _840 {
        flex-wrap: wrap;
        gap: 30px;
    }

    .phone {
        font-size: 32px;
    }
}

.form-row {
    // display: flex;
    // gap: 24px;
    // align-items: center;
    margin-bottom: 20px;
    @include _840 {
        // flex-wrap: wrap;
    }

    &:has(span.error:not(:empty)) {
        margin-bottom: 10px;
    }
}

.container {
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1300px;
    @include _2000 {
        max-width: 1460px;
    }
}

.text-s {
    font-size: 14px;
    @include _840 {
        font-size: 12px;
    }
}

.text-m {
    font-size: 18px;
    @include _840 {
        font-size: 16px;
    }
}

.text-xl {
    font-size: 24px;
    @include _840 {
        font-size: 20px;
    }
}

.text-muted {
    color: var(--gray);
}

.btn {
    @include no-btn();
    border: 1px solid;
    color: #fff;
    padding: 20px 75px;
    position: relative;
    overflow: hidden;
    @include _840 {
        padding: 16px 60px;
        width: 100%;
    }

    &.--fill {
        // background-color: var(--primary);
        border-color: var(--primary);
        @include _840 {
            padding: 16px 25px;
        }

        &::after {
            background-color: var(--primary);
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            transform: none;
            transition: .2s;
        }

        &:hover {
            background-color: transparent !important;
            color: #4D5AFF;
            transition: .2s;

            &::after {
                transform: translateX(-100%);
            }
        }
    }

    &.--white {
        background-color: #fff;
        color: #4D5AFF;
        border-color: #4D5AFF;
        font-weight: 500;
    }

    &.--trans {
        background-color: transparent;
        border-color: #fff;
        transition: .2s;

        &:hover {
            // color: var(--primary);
            background-color: var(--primary);
        }
    }

    &.--radius {
        border-radius: 50px;
    }
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: 90px;
    @include _840 {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    h2 {
        @include _840 {
            order: 3;
        }
    }

    .num {
        font-size: 96px;
        font-weight: 300;
        @include _840 {
            font-size: 60px;
            order: 1;
        }

        &:nth-child(2) {
            margin: 0 44px 0 0;
        }
    }

    .line {
        background-color: transparent;
        height: 3px;
        flex: 1;
        max-width: 314px;
        margin: 0 44px;
        position: relative;
        @include _840 {
            order: 2;
            flex: none;
            max-width: 100%;
            margin: 20px 0;
            width: 60%;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 0;
            transition: width .5s;
        }

        &.show::before {
            background-color: var(--primary);
            width: 100%;
        }

        &:first-child {
            margin: 0;
            margin-right: 44px;
            @include _840 {
                margin: 20px 0;
            }
        }
    }
}

.green {
    background-color: var(--green);
    color: #333;
    padding: 0 3px;
}

.purple {
    color: #4D5AFF;
}

.presantation {
    color: #fff;
    background-image: url(../img/progoz/presant-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    @include _840 {
        background-position: 80%;
    }

    &__inner {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 25px;
        @include _840 {
            gap: 20px;
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
}

.about {

    h2 {
        margin-bottom: 50px;
        text-align: center;
    }

    .about-row {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        margin-bottom: 60px;
        padding-bottom: 60px;
        position: relative;
        @include _840 {
            flex-direction: column;
        }

        &.--vertical {
            flex-direction: column;

            .about-row__desc {
                max-width: 80%;
                @include _840 {
                    max-width: 100%;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 70%;
            height: 2px;
            background-color: #4D5AFF;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }

        h3 {
            margin-bottom: 20px;
        }

        &__desc {
            max-width: 530px;
        }

        img {
            cursor: zoom-in;
        }
    }
}

#modal-2 {
    .modal__container {
        max-width: 100%;
    }
}