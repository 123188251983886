.subscribe {
    background-image: url(../img/subscribe-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background-attachment: fixed;
    color: #fff;
    padding: 110px 0;
    @include _840 {
        padding: 100px 20px;
    }

    &__logo {
        margin-top: 60px;
        margin-right: 160px;
        @include _840 {
            margin: 0;
            margin-bottom: 30px;
            width: 50%;
        }
    }

    &__inner {
        display: flex;
        @include _840 {
            flex-wrap: wrap;
        }
    }

    &__block {

        h2 {
            margin-bottom: 26px;
        }

        p {
            margin-bottom: 10px;
        }
    }
}